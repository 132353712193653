








import PageTitle from "@/components/PageTitle.vue"
import { Target } from "@/includes/types/Board.types"

import ServicesCart from 'piramis-base-components/src/plugins/AdminTemplate/pages/services-cart.vue'
import AListCustom from 'piramis-base-components/src/components/AListCustom/AListCustom.vue'
import AListCustomItem from "piramis-base-components/src/components/AListCustom/AListCustomItem.vue"

import Vue from 'vue'
import { Component } from "vue-property-decorator"
import moment from "moment"

@Component({
  data() {
    return {
      moment,
    }
  },
  components: {
    ServicesCart,
    PageTitle,
    AListCustom,
    AListCustomItem
  }
})
export default class BuyChannel extends Vue {
  get channel(): Target {
    return this.$store.state.channelsState.activeChannel
  }

}
